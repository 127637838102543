.formkit-form * {
  box-sizing: border-box;
}
.formkit-form legend {
  border: none;
  font-size: inherit;
  margin-bottom: 10px;
  padding: 0;
  position: relative;
  display: table;
}
.formkit-form fieldset {
  border: 0;
  padding: 0.01em 0 0 0;
  margin: 0;
  min-width: 0;
}
.formkit-form body:not(:-moz-handler-blocked) fieldset {
  display: table-cell;
}
.formkit-form p {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
}
.formkit-form[data-format='modal'] {
  display: none;
}
.formkit-form[data-format='slide in'] {
  display: none;
}
.formkit-form .formkit-input,
.formkit-form .formkit-select,
.formkit-form .formkit-checkboxes {
  width: 100%;
}
.formkit-form .formkit-button,
.formkit-form .formkit-submit {
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 15px;
  overflow: hidden;
  padding: 0;
  position: relative;
  vertical-align: middle;
}
.formkit-form .formkit-button:hover,
.formkit-form .formkit-submit:hover,
.formkit-form .formkit-button:focus,
.formkit-form .formkit-submit:focus {
  outline: none;
}
.formkit-form .formkit-button:hover > span,
.formkit-form .formkit-submit:hover > span,
.formkit-form .formkit-button:focus > span,
.formkit-form .formkit-submit:focus > span {
  background-color: hsl(340, 63%, 63%);
}
.formkit-form .formkit-button > span,
.formkit-form .formkit-submit > span {
  display: block;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  padding: 12px 24px;
}
.formkit-form .formkit-input {
  background: #ffffff;
  font-size: 15px;
  padding: 12px;
  border: 1px solid #e3e3e3;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  line-height: 1.4;
  margin: 0;
  -webkit-transition: border-color ease-out 300ms;
  transition: border-color ease-out 300ms;
}
.formkit-form .formkit-input:focus {
  outline: none;
  border-color: #1677be;
  -webkit-transition: border-color ease 300ms;
  transition: border-color ease 300ms;
}
.formkit-form .formkit-input::-webkit-input-placeholder {
  color: #848585;
}
.formkit-form .formkit-input::-moz-placeholder {
  color: #848585;
}
.formkit-form .formkit-input:-ms-input-placeholder {
  color: #848585;
}
.formkit-form .formkit-input::placeholder {
  color: #848585;
}
.formkit-form [data-group='dropdown'] {
  position: relative;
  display: inline-block;
  width: 100%;
}
.formkit-form [data-group='dropdown']::before {
  content: '';
  top: calc(50% - 2.5px);
  right: 10px;
  position: absolute;
  pointer-events: none;
  border-color: #4f4f4f transparent transparent transparent;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  height: 0;
  width: 0;
  z-index: 999;
}
.formkit-form [data-group='dropdown'] select {
  height: auto;
  width: 100%;
  cursor: pointer;
  color: #333333;
  line-height: 1.4;
  margin-bottom: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 15px;
  padding: 12px 25px 12px 12px;
  border: 1px solid #e3e3e3;
  background: #ffffff;
}
.formkit-form [data-group='dropdown'] select:focus {
  outline: none;
}
.formkit-form [data-group='checkboxes'] {
  text-align: left;
  margin: 0;
}
.formkit-form [data-group='checkboxes'] [data-group='checkbox'] {
  margin-bottom: 10px;
}
.formkit-form [data-group='checkboxes'] [data-group='checkbox'] * {
  cursor: pointer;
}
.formkit-form [data-group='checkboxes'] [data-group='checkbox']:last-of-type {
  margin-bottom: 0;
}
.formkit-form
  [data-group='checkboxes']
  [data-group='checkbox']
  input[type='checkbox'] {
  display: none;
}
.formkit-form
  [data-group='checkboxes']
  [data-group='checkbox']
  input[type='checkbox']
  + label::after {
  content: none;
}
.formkit-form
  [data-group='checkboxes']
  [data-group='checkbox']
  input[type='checkbox']:checked
  + label::after {
  border-color: #ffffff;
  content: '';
}
.formkit-form
  [data-group='checkboxes']
  [data-group='checkbox']
  input[type='checkbox']:checked
  + label::before {
  background: #10bf7a;
  border-color: #10bf7a;
}
.formkit-form [data-group='checkboxes'] [data-group='checkbox'] label {
  position: relative;
  display: inline-block;
  padding-left: 28px;
}
.formkit-form [data-group='checkboxes'] [data-group='checkbox'] label::before,
.formkit-form [data-group='checkboxes'] [data-group='checkbox'] label::after {
  position: absolute;
  content: '';
  display: inline-block;
}
.formkit-form [data-group='checkboxes'] [data-group='checkbox'] label::before {
  height: 16px;
  width: 16px;
  border: 1px solid #e3e3e3;
  background: #ffffff;
  left: 0;
  top: 3px;
}
.formkit-form [data-group='checkboxes'] [data-group='checkbox'] label::after {
  height: 4px;
  width: 8px;
  border-left: 2px solid #4d4d4d;
  border-bottom: 2px solid #4d4d4d;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 4px;
  top: 8px;
}
.formkit-form .formkit-alert {
  background: #f9fafb;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  list-style: none;
  margin: 25px auto;
  padding: 12px;
  text-align: center;
  width: 100%;
}
.formkit-form .formkit-alert:empty {
  display: none;
}
.formkit-form .formkit-alert-success {
  background: #d3fbeb;
  border-color: #10bf7a;
  color: #0c905c;
}
.formkit-form .formkit-alert-error {
  background: #fde8e2;
  border-color: #f2643b;
  color: #ea4110;
}
.formkit-form .formkit-spinner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 0;
  width: 0;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  text-align: center;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.formkit-form .formkit-spinner > div {
  margin: auto;
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 0.3;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: formkit-bouncedelay-formkit-form-data-uid-4a352cb1fd- 1.4s
    infinite ease-in-out both;
  animation: formkit-bouncedelay-formkit-form-data-uid-4a352cb1fd- 1.4s infinite
    ease-in-out both;
}
.formkit-form .formkit-spinner > div:nth-child(1) {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.formkit-form .formkit-spinner > div:nth-child(2) {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.formkit-form .formkit-submit[data-active] .formkit-spinner {
  opacity: 1;
  height: 100%;
  width: 50px;
}
.formkit-form .formkit-submit[data-active] .formkit-spinner ~ span {
  opacity: 0;
}
@-webkit-keyframes formkit-bouncedelay-formkit-form-data-uid-4a352cb1fd- {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes formkit-bouncedelay-formkit-form-data-uid-4a352cb1fd- {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
.formkit-form {
  max-width: 700px;
  overflow: hidden;
}
.formkit-form [data-style='full'] {
  width: 100%;
  display: block;
}
.formkit-form .formkit-header {
  margin-top: 0;
  margin-bottom: 20px;
  font-family: inherit;
}
.formkit-form .formkit-subheader {
  margin: 15px 0;
}
.formkit-form .formkit-column {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.formkit-form .formkit-column:nth-child(2) {
  border-top: 1px solid #e9ecef;
}
.formkit-form .formkit-field {
  margin: 0 0 15px 0;
}
.formkit-form .formkit-input,
.formkit-form .formkit-submit {
  width: 100%;
}
.formkit-form .formkit-guarantee {
  font-size: 13px;
  margin: 0 0 15px 0;
}
.formkit-form .formkit-guarantee > p {
  margin: 0;
}
.formkit-form .formkit-powered-by {
  color: #7d7d7d;
  display: block;
  font-size: 12px;
  margin-bottom: 0;
  text-align: center;
}
.formkit-form .formkit-powered-by[data-active='false'] {
  opacity: 0.5;
}
.formkit-form[min-width~='600'] [data-style='full'],
.formkit-form[min-width~='700'] [data-style='full'],
.formkit-form[min-width~='800'] [data-style='full'] {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}
.formkit-form[min-width~='600'] .formkit-submit,
.formkit-form[min-width~='700'] .formkit-submit,
.formkit-form[min-width~='800'] .formkit-submit {
  width: auto;
}
.formkit-form[min-width~='600'] .formkit-column:nth-child(2),
.formkit-form[min-width~='700'] .formkit-column:nth-child(2),
.formkit-form[min-width~='800'] .formkit-column:nth-child(2) {
  border-top: none;
}
